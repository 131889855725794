// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, Parallax } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../css/_vendor/swiper.scss';

// ===================================================
// default image sliders
// ===================================================
const image_sliders = document.querySelectorAll('[data-image-gallery]');

image_sliders.forEach((item) => {
    let swiperThumbs;
    const thumbnails = item.querySelector('[data-image-gallery-thumbnails]');

    if (thumbnails) {
        let breakpoints = {
            768: {
                slidesPerView: 8.5
            }
        }

        swiperThumbs = new Swiper(thumbnails.querySelector('.swiper'), {
            slidesPerView: 4.5,
            spaceBetween: 10,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: breakpoints,
            navigation: {
                nextEl: thumbnails.querySelector('.swiper-button-next'),
                prevEl: thumbnails.querySelector('.swiper-button-prev')
            },
            modules: [Navigation]
        });
    }

    item.querySelectorAll('.swiper').forEach((slider) => {
        if (slider.parentNode.hasAttribute('data-image-gallery')) {
            new Swiper(slider, {
                slidesPerView: 'auto',
                loop: true,
                grabCursor: true,
                spaceBetween: 5,
                centeredSlides: true,
                navigation: {
                    nextEl: slider.querySelector('.swiper-button-next'),
                    prevEl: slider.querySelector('.swiper-button-prev')
                },
                thumbs: {
                    swiper: swiperThumbs,
                },
                modules: [Navigation, Thumbs]
            });
        }
    });
});


// ===================================================
// Testimonial slider
// ===================================================
const testimonial_sliders = document.querySelectorAll('[data-testimonial-slider]');
testimonial_sliders.forEach((item) => {
    new Swiper(item.querySelector('.swiper'), {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        grabCursor: true,
        autoHeight: true,
        spaceBetween: '5%',
        centeredSlides: true,
        navigation: {
            nextEl: item.querySelector('.swiper-button-next'),
            prevEl: item.querySelector('.swiper-button-prev')
        },
        pagination: {
            el: item.querySelector('.swiper-pagination'),
            clickable: true,
        },
        modules: [Navigation, Pagination]
    });
});


// ===================================================
// Horizontal Slider
// ===================================================
const horizontal_sliders = document.querySelectorAll('[data-horizontal-slider]');
horizontal_sliders.forEach((item) => {
    new Swiper(item.querySelector('.swiper'), {
        slidesPerView: 'auto',
        loop: true,
        grabCursor: true,
        spaceBetween: 5,
        centeredSlides: true,
        navigation: {
            nextEl: item.querySelector('.swiper-button-next'),
            prevEl: item.querySelector('.swiper-button-prev')
        },
        modules: [Navigation]
    });
});


// ===================================================
// Card Slider
// ===================================================
const card_slider = document.querySelectorAll('[data-card-slider]');
card_slider.forEach((item) => {
    let breakpoints = {
        1200: {
            slidesPerView: 3.1
        },
        768: {
            slidesPerView: 2.1
        },
    }

    new Swiper(item.querySelector('.swiper'), {
        slidesPerView: '1.1',
        grabCursor: true,
        spaceBetween: 20,
        breakpoints: breakpoints,
        navigation: {
            nextEl: item.querySelector('.swiper-button-next'),
            prevEl: item.querySelector('.swiper-button-prev')
        },
        modules: [Navigation]
    });
});


// ===================================================
// Featured Events Slider
// ===================================================
const featured_event_slider = document.querySelectorAll('[data-featured-event-slider]');
featured_event_slider.forEach((item) => {
    let breakpoints = {
        1024: {
            slidesPerView: 4.25,
            spaceBetween: 20
        },
        550: {
            slidesPerView: 3.25,
            spaceBetween: 10
        },
    }

    new Swiper(item.querySelector('.swiper'), {
        slidesPerView: '1.5',
        grabCursor: true,
        spaceBetween: 10,
        breakpoints: breakpoints,
        navigation: {
            nextEl: item.querySelector('.swiper-button-next'),
            prevEl: item.querySelector('.swiper-button-prev')
        },
        modules: [Navigation]
    });
});


// ===================================================
// Timeline Slider
// ===================================================
document.addEventListener('DOMContentLoaded', () => {
    let breakpoints = {
        768: {
            slidesPerView: 2.25
        },
    }

    const swiper = new Swiper('.timeline-slider', {
        slidesPerView: 1.25,
        grabCursor: true,
        spaceBetween: 50,
        autoHeight: true,
        direction: "horizontal",
        effect: "slide",
        parallax: true,
        speed: 500,
        breakpoints: breakpoints,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        modules: [Navigation, Parallax]
    });

    const slides = document.querySelectorAll('.swiper-slide');
    const totalSlides = slides.length;
    const ranges = {};

    slides.forEach((slide, index) => {
        let slideRange = slide.dataset.range;
        if (slideRange) {
            slideRange = slideRange.trim().replace(/\s+/g, '').toLowerCase();
            if (!ranges[slideRange]) {
                ranges[slideRange] = { startIndex: index, endIndex: index };
            } else {
                ranges[slideRange].endIndex = index;
            }
        }
    });

    const rangeButtons = document.querySelectorAll('.timeline-year-range-navigation button');

    // Convert ranges into an array for easy handling
    const rangeData = Object.entries(ranges)
    .filter(([key]) => key && key.trim() !== '')
    .map(([key, { startIndex, endIndex }]) => ({
        key,
        startIndex,
        endIndex,
        length: (endIndex - startIndex + 1)
    }));

    // Clear existing segments if any
    const progressBarBackground = document.querySelector('.timeline-progress-bar-background');
    progressBarBackground.innerHTML = '';

    // Create segments proportional to each range’s slide count
    rangeData.forEach(({ length }, index) => {
        const segment = document.createElement('div');
        segment.classList.add('progress-segment');
        segment.style.flex = length.toString();
        progressBarBackground.appendChild(segment);
    });

    const progressBarFill = document.querySelector('.timeline-progress-bar-fill');

    function updateProgressBar() {
        if (totalSlides > 1) {
            // If at the end, set to 100% to fill the bar completely
            if (swiper.isEnd) {
                progressBarFill.style.width = '100%';
            } else {
                const currentIndex = swiper.activeIndex;
                const progressPercentage = (currentIndex / (totalSlides - 1)) * 100;
                progressBarFill.style.width = progressPercentage + '%';
            }
        } else {
            progressBarFill.style.width = '100%';
        }
    }

    function highlightActiveRange(activeRangeKey) {
    rangeButtons.forEach((btn) => {
        btn.classList.toggle('active-range', btn.dataset.range === activeRangeKey);
    });

    rangeMarkers.forEach((marker) => {
        const markerRangeKey = marker.dataset.range;
        const targetRange = rangeData.find((r) => r.key === markerRangeKey);
        if (targetRange) {
            // Add active class if the marker's range has been reached
            const isReached = swiper.activeIndex >= targetRange.startIndex;
            if (isReached) {
                marker.classList.add('range-marker--active');
            } else {
                marker.classList.remove('range-marker--active');
            }

            // Add current class only to the active range marker
            const isCurrent = swiper.activeIndex >= targetRange.startIndex && swiper.activeIndex <= targetRange.endIndex;
            if (isCurrent) {
                marker.classList.add('range-marker--current');
            } else {
                marker.classList.remove('range-marker--current');
            }
        }
    });
}

    // Position buttons at the start of their range segment
    rangeData.forEach((rangeObj, i) => {
        const btn = rangeButtons[i];
        if (!btn) return;
        if (totalSlides > 1) {
            const segmentStartPercent = (rangeObj.startIndex / (totalSlides - 1)) * 100;
            btn.style.left = segmentStartPercent + '%';
        } else {
            btn.style.left = '0%';
        }
    });

    // Create markers container
    const markersContainer = document.createElement('div');
    markersContainer.style.position = 'relative';
    markersContainer.style.width = '100%';
    markersContainer.style.height = '0px';
    markersContainer.style.zIndex = '12';
    document.querySelector('.timeline-progress-bar-container').appendChild(markersContainer);

    const rangeMarkers = [];
    rangeData.forEach((rangeObj, i) => {
        const btn = rangeButtons[i];
        if (!btn) {
            console.warn('No button for range:', rangeObj.key);
            return;
        }

        const marker = document.createElement('div');
        marker.classList.add('timeline-range-marker');
        marker.dataset.range = rangeObj.key;

        if (totalSlides > 1) {
            const segmentStartPercent = (rangeObj.startIndex / (totalSlides - 1)) * 100;
            marker.style.left = segmentStartPercent + '%';
        } else {
            marker.style.left = '0%';
        }

        markersContainer.appendChild(marker);
        rangeMarkers.push(marker);
    });

    // On button click, jump to that range start
    rangeButtons.forEach(btn => {
        btn.addEventListener('click', () => {
            const rangeKey = btn.dataset.range
                .trim()
                .replace(/\s+/g, '')
                .toLowerCase();
            const targetRange = rangeData.find(r => r.key === rangeKey);
            if (targetRange) {
                swiper.slideTo(targetRange.startIndex);
                highlightActiveRange(rangeKey);
                updateProgressBar();
            }
        });
    });

    // On slide change
    swiper.on('slideChange', () => {
        const currentIndex = swiper.activeIndex;
        let currentRangeKey = null;

        for (const { key, startIndex, endIndex } of rangeData) {
            if (currentIndex >= startIndex && currentIndex <= endIndex) {
                currentRangeKey = key;
                break;
            }
        }

        if (currentRangeKey) {
            highlightActiveRange(currentRangeKey);
        } else if (swiper.isEnd && rangeData.length > 0) {
            // Highlight the last range at the end
            const lastRange = rangeData[rangeData.length - 1].key;
            highlightActiveRange(lastRange);
        }

        updateProgressBar();
    });

    // Initialize states
    if (rangeButtons.length > 0) {
        // Highlight the first range by using the first button's range key
        highlightActiveRange(rangeButtons[0].dataset.range);
    }
    updateProgressBar();

});